import '../../../../_newcommon/js/events/zvuk';

import $ from 'jquery'
import player from 'js/withoutMangle/modules/player/player'
import kinetic from 'js/modules/kinetic'
import reloadLazy from 'js/withoutMangle/modules/lazyLoad'
import 'jquery-ui-sortable-npm' // 'jquery-ui-sortable-npm/jquery-ui-sortable.min'
import init from 'js/withoutMangle/modules/ajaxPagination'
import listen from 'js/withoutMangle/modules/listenAll'
import 'slick-carousel'
import styles from 'styl/commons/common.styl'


// core version + navigation, pagination modules:
import Swiper from 'swiper/bundle';
import { Navigation } from 'swiper/modules';
// import Swiper and modules styles


// init Swiper:
const swiper = new Swiper('.swiper', {
    // configure Swiper to use modules
    modules: [Navigation],
    slidesPerView: 'auto',
    // spaceBetween: 8,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

$(document).ready(function () {
    /* search */
    require(['js/withoutMangle/modules/commons.js']);
    require(['js/withoutMangle/modules/auth.js']);
    require(['js/withoutMangle/modules/favorite.js']);

    /* playlist */
    require(['js/withoutMangle/modules/playlist.js']);

    /* sortable */
    require(['js/withoutMangle/modules/sortable.js']);

    /* sortableFavorite */
    require(['js/withoutMangle/modules/sortableFavorite.js']);

    /* search and add in playlist */
    require(['js/withoutMangle/modules/search_inPlaylist.js']);

    /* Title youtube widget */
    // var size = 73,
    //     titleVideo= $('.titleVideo'),
    //     newsText = titleVideo.text();
    //
    // if(newsText.length > size){
    //     titleVideo.text(newsText.slice(0, size) + '...');
    // }


    //Если аватарки юзера нет - грузим заглушку принудительно.
    if (app.authorized) {
        var pug = $('.myMusic > img'),
            myUrl = pug.attr('data-src');
        function checkExists(imageUrl, callback) {
            var img = new Image();
            img.onerror = function () {
                callback(false);
            };
            img.src = imageUrl;
        }
        checkExists(myUrl, function (exists) {
            if (!exists) {
                pug.removeAttr('data-src')
                    .removeAttr('lazyload');
                pug.attr('src', '/images/svg/pug_auth.svg');
            }
        });
    }

    /* kinetik */
    $.each($('.kinetic'), function () {
        kinetic.init($(this));
    })

    var $songs = $('.songs');
    player.init($songs);
    //listen
    listen($('.songsListen .item'))

    /* radio */
    var radioClick = function () { };
    require(['js/withoutMangle/modules/radio.js'], function (radio) {
        radioClick = radio;
    });

    // $('.button_more').on('click', function () {
    //     var $parent = $(this).parent();
    //     if ($parent.hasClass('collapsedButton')) {
    //         $(this).html('Скрыть');
    //         $parent.removeClass('collapsedButton');
    //     } else {
    //         $(this).html('Еще');
    //         $parent.addClass('collapsedButton');
    //     }
    // });

    var $pageDescriptionMore = $('.pageDescriptionMore');
    if ($pageDescriptionMore.length) {
        if ($pageDescriptionMore.text().length < 250) {
            $pageDescriptionMore.addClass('showMore');
        }
        $('.pageDescriptionMoreButton', $pageDescriptionMore).on('click', function () {
            $(this).closest('.pageDescriptionMore').addClass('showMore');
        })
    }


    /* ancor */
    $('.anchor').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({ scrollTop: ($($(this).attr('href')).offset().top - 70) }, 'fast');
        return false;
    });

    if ($('.js-abTest').length && window.ya_global && window.ya_global.reachGoal) {
        window.ya_global.reachGoal('ab_test', { abtest: $('.js-abTest').data('param') });
    }

    /* like - dislike */
    // require(['js/modules/likes.js']);

    // init(reloadLazy, true);
    // require(['js/modules/popular.js']);
    // require(['js/modules/youtubewidget.js']);

    /* feedback*/
    require(['js/modules/feedback.js']);
    /* btn-ajax */
    require(['js/modules/btn_ajax.js']);

    /* shared-button */
    $("#show-share").on("click", function () {
        $('.shared_buttons .support').slideToggle(150, function () {
            $("#show-share").toggleClass('active');
            $("#share").toggle();
        });
        $("#share").addClass('inited');
    });

    //Hamburger menu
    $('#hamburger-icon').click(function () {
        document.getElementById("mobileShow").style.top = "60px";
    });

    /*Dropdown Menu*/
    require(['js/withoutMangle/modules/dropdownmenu.js']);
    /*End Dropdown Menu*/

    /* get version */
    var isMobile = $(document.body).hasClass('mobile');
    var isTablet = $(document.body).hasClass('tablet');

    /* Hide searchbar */
    var mywindow = $(window);
    var mypos = mywindow.scrollTop();
    var up = false;
    var newscroll;
    mywindow.scroll(function () {
        newscroll = mywindow.scrollTop();
        if (mypos > 100 && isMobile) {
            if (newscroll > mypos && !up) {//hide
                $('#inHeader').stop().slideToggle(0);
                up = true;
            } else if (newscroll < mypos && up) {//show
                $('#inHeader').stop().slideToggle(0);
                up = false;
            }
        }
        mypos = newscroll;
    });



    //adaptive width select

    var select = $('#selectArtist');
    var reset = $('.inInputSearch .reset');

    function setSelectWidth(length) {
        var width = length * 7.6 + 4;
        var right = Math.ceil(55 + width);

        select.css({ width: width });
        reset.css({ right: right });

        setInputWidth(width);
    }

    function setInputWidth(width) {
        var input = $('#main-search input');
        input.width('100%');
        var inputWidth = input.width();
        var selectWidth = width + 35 || $('#selectArtist').width();

        input.width(inputWidth - selectWidth - 60);
    }

    if (!isMobile && !isTablet) {
        var nameSelectLength = select[0].selectedOptions[0].label.length;
        setSelectWidth(nameSelectLength);
    }

    $('#main-search .dropdown-menu li').click(function () {
        if (!isMobile && !isTablet) {
            var name = $(this)[0].innerText;
            var length = name.length;

            setSelectWidth(length);
        }
    });



    /* Slider Init
        (Топ сборников) */
    require(['js/modules/slickSliderDots.js']);

    /* Slider Init
        (Популярные сборники) */
    $('.sliderSLickMain').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        rows: 0,
        autoplaySpeed: 5000,
        dots: true
    });

    /* Filter */
    /* Start globalChoice */
    require(['js/withoutMangle/modules/globalChoice.js']);
    /* End globalChoice */

    /* Start Zvuk.News widget slider */
    var slideIndex = 0;
    showSlides(0);

    $('.arrow-left').click(function () {
        showSlides(-1);
    });
    $('.arrow-right').click(function () {
        showSlides(1);
    });

    function showSlides(change) {
        var slides = document.getElementsByClassName("slide");
        if (!slides.length) return;
        $(slides[slideIndex]).removeAttr('style');
        slideIndex += change;
        if (slideIndex > slides.length - 1) {
            slideIndex = 0
        }
        if (slideIndex < 0) {
            slideIndex = slides.length - 1
        }
        slides[slideIndex].style.display = "block";
    }
    /* End Zvuk.News widget slider */

    //Events
    require(['js/modules/ymTarget.js']);

    if (!app.authorized) {
        $('.myMusic, .favorite').click(function () {
            if (window.ya_global && window.ya_global.reachGoal) {
                window.ya_global.reachGoal('showpopup-auth-click');
            }
        });
    }

    $('.favorite').on('click', function () {
        if (!app.authorized) {
            window.ya_global.reachGoal('like-no-auth');
        } else {
            window.ya_global.reachGoal('like');
        }
    });

    if (document.cookie.indexOf("user_was_authorized=") != -1) {
        if (window.ya_global && window.ya_global.reachGoal) {
            window.ya_global.reachGoal('auth-users-click');
        }
        document.cookie = "user_was_authorized=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    //Dark Theme
    if (app.authorized) require(['js/modules/darkTheme.js']);

    if (window.app.tmf_url) {
        // if (true) {

        let freeClick = !~document.cookie.indexOf("teleTwoDownloadFreeClick");

        if (!~document.cookie.indexOf("teleTwoDownload")) {

            let enable = true;

            let dls = document.querySelectorAll('a.' + (styles['dl'] || 'dl'));

            [...dls].forEach(function (dlEl) {
                dlEl.addEventListener('click', function (e) {

                    if (freeClick) {
                        freeClick = false;
                        document.cookie = "teleTwoDownloadFreeClick=; path=/;";
                        return;
                    }
                    if (!enable) return;

                    e.preventDefault();

                    enable = false;
                    document.cookie = "teleTwoDownload=; path=/;";

                    window.open(window.app.tmf_url, '_blank').focus();


                });
            })

        }
    }

});
