import $ from 'jquery'

var optionsSupport = false;
try {
    var options = Object.defineProperty({}, "passive", {
        get: function () {
            optionsSupport = true;
        }
    });
    window.addEventListener("test", null, options);
} catch (err) {
}

function addListener(params) {
    if (window.addEventListener) {
        window.addEventListener(params.type, params.fn, optionsSupport ? params.options : false);
    } else {
        window.attachEvent("on" + params.type, params.fn);
    }
}

function removeListener(params) {
    if (window.removeEventListener) {
        window.removeEventListener(params.type, params.fn, optionsSupport ? params.options : false);
    } else {
        window.detachEvent("on" + params.type, params.fn);
    }
}

function show(elem) {
    var _this = $(elem);
    var dataAttr = _this.data('src') || _this.data('lazy');
    if (dataAttr && dataAttr !== '' && dataAttr !== '/img/top/') {
        var oldSrc = _this.attr('src');
        var fallback = function (event) {
            removeListeners(event);
            event.target.setAttribute('src', oldSrc);
        };
        var removeListeners = function (event) {
            event.target.removeEventListener('error', fallback);
            event.target.removeEventListener('load', removeListeners);
        };
        elem[0].addEventListener('error', fallback);
        elem[0].addEventListener('load', removeListeners);
        _this.attr('src', dataAttr);
        _this.removeAttr('lazyload');
    }
}

var heightArray = [];

function calcArray(recalc) {
    heightArray = (
        recalc
            ? heightArray
            : $('img[lazyload]').toArray()
                .reduce(function (acc, elem) {
                    acc.push([0, elem]);
                    return acc
                }, []))
        .reduce(function (acc, elem) {
            elem = $(elem[1]);
            var offset = elem.offset();
            if (offset.top === 0 && offset.left === 0) {
                var visibleOffsetParent = elem.parents('*:visible').first();
                if (visibleOffsetParent.hasClass('lazyLoadBlockParentsOffset')) return acc;
                offset = visibleOffsetParent.offset();
            }
            acc.push([innerHeight * 1.5 - offset.top, elem]);
            return acc;
        }, []).sort(function (a, b) {
            return b[0] - a[0]
        })
}

function scrollHandler() {
    while (heightArray.length && (heightArray[0][0] + pageYOffset > 0)) {
        show(heightArray.shift()[1]);
    }
}

function reloadLazy() {
    calcArray();
    scrollHandler();
}

function lazyImg() {
    addListener({ type: 'scroll', fn: scrollHandler, options: { passive: true } });
    addListener({
        type: 'resize', fn: function () {
            calcArray(true);
            scrollHandler();
        }, options: { passive: true }
    });
    reloadLazy()
}

addListener({ type: 'load', fn: lazyImg });

export default reloadLazy;